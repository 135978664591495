import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useNavigate } from 'react-router-dom';
import golf from "../images/ground.png"
// import golf from "../images/golf-1.svg"
import logo from "../images/logo.svg"
import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { useStoreActions, useStoreState } from "easy-peasy";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";     
//core
import "primereact/resources/primereact.min.css";                                       
import axios from "axios";
import constants from "../constants/constants";      

const ChangePassword = () => {
    const toast = useRef(null);
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const togglePasswordVisibility1 = () => {
        setShowPassword1(!showPassword1);
    };

    const {
        register, handleSubmit,
        // reset,
        formState: { errors },
        watch,
    } = useForm();

    const setUserDetail = useStoreActions((actions) => actions.tabModel.setUserDetail);
    const userDetail = useStoreState((state) => state.tabModel.userDetail);
    const password = watch("password");

    const onSubmit = (data) => {
        const payload = {
            userPassword: data.password,
            userMobile: userDetail?.userMobile,
        }

        setIsLoading(true);
        axios.post(constants.URL.CHANGE_PASSWORD,payload)
        .then((resp)=>{
            navigate("/")
            toast.current.show({ severity: "success", summary: "Success", detail: "Password changed successfully" });
        }).catch((e)=>{
            toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.error });
            console.error(e);
        }).finally(()=>{
            setIsLoading(false);
        })
    }

    return (
        <div className="login-wrapper">
        <Toast ref={toast} />
            <div className="ls-page">
                <img src={golf} className="" alt="golf-ground" />
                <img src={logo} className="ls-logo" alt="golf-ground" />
                <div className="form-wrapper">
                    <form onSubmit={handleSubmit(onSubmit)} className="error_msg">
                        <h4 className="ls-heading mb-3">Reset Password</h4>
                        <div>
                            <div className="field">
                                <label htmlFor="password" className="block text-900 font-medium mb-2">New Password</label>
                                <div className="relative">
                                <InputText id="password" className="w-full" type={showPassword ? "text" : "password"}
                                    defaultValue={""}
                                    {...register("password", {
                                        required: true,
                                    })}
                                />
                                <span className="absolute eye-icon-position cursor-pointer" onClick={togglePasswordVisibility}>
                                    {showPassword ? (
                                        <i className="pi pi-eye-slash" style={{ color: '#708090', fontSize: "16px" }}></i>
                                    ) : (
                                        <i className="pi pi-eye" style={{ color: '#708090', fontSize: "16px" }}></i>
                                    )}
                                </span>
                                </div>
                                {errors?.password?.type === "required" && <p className="p-error">This field is required</p>}
                            </div>

                            <div className="field mb-2 mt-1">
                                <label htmlFor="confirmPassword" className="block text-900 font-medium mb-2">Confirm Password</label>
                                <div className="relative">
                                    <InputText id="confirmPassword" type={showPassword1 ? "text" : "password"} className="w-full"
                                        defaultValue={""}
                                        {...register("confirmPassword", {
                                            required: true,
                                            validate: (value) => value === password, // Validate if the value matches the password field
                                        })}
                                    />
                                    <span className="absolute eye-icon-position cursor-pointer" onClick={togglePasswordVisibility1}>
                                        {showPassword1 ? (
                                            <i className="pi pi-eye-slash" style={{ color: '#708090', fontSize: "16px" }}></i>
                                        ) : (
                                            <i className="pi pi-eye" style={{ color: '#708090', fontSize: "16px" }}></i>
                                        )}
                                    </span>
                                </div>

                                {errors?.confirmPassword?.type === "required" && <p className="p-error">This field is required</p>}
                                {errors.confirmPassword?.type === "validate" && (
                                    <p className="p-error">Passwords do not match</p>
                                )}
                            </div>
                            <div className="ls-forget-text text-right mb-3" onClick={()=> {navigate("/")}}>Back to Login</div>
                            <div className="mt-3 flex justify-content-center">
                                <div className="ls-btn-wrapper">
                                    <Button label="Update" className="ls-login-btn" />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ChangePassword;
