import './App.css';
import React from 'react';
import models from "./models/Models";
import { StoreProvider, createStore, } from "easy-peasy";
import Root from './Root';
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";
import "/node_modules/primeflex/primeflex.css";

const storeModel = createStore(models);

function App() {
  return (
    <StoreProvider store={storeModel}>
      <Root />
    </StoreProvider>
  )
}

export default App;