import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import SidebarMenu from '../components/SidebarMenu';
import back from '../images/icon_arrow_left.svg';
import timer from '../images/icon_timer.svg';
import constants from "../constants/constants";
import { Toast } from "primereact/toast";
import { useStoreState } from "easy-peasy";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import { InputNumber } from 'primereact/inputnumber';

function Configuration() {
    const toast = useRef(null);
    let today = new Date();
    const [noOfDays, setNoOfDays] = useState(null);
    const slot_combo = ["1", "2", "3", "4",];
    const player_combo = ["2", "3"];
    const [startTime, setStartTime] = useState();
    const [defaultStartTime, setDefaultStartTime] = useState("");
    const [cancelTimeInHours, setCancelTimeInHours] = useState("");
    const [minPlayer, setMinPlayer] = useState("");
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const userDetail = useStoreState((state) => state.tabModel.userDetail);
    // console.log(minPlayer);


    useEffect(() => {
        setIsLoading(true);
        const payload = {}
        axios
            .post(constants.URL.FETCH_CONFIG, payload)
            .then((resp) => {
                setConfig(resp.data);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }, []);
    // console.log(data);

    const setConfig =(dataconfig) =>{
        if(dataconfig!=null){
            var starttime= getConfigfromKey("starttime",dataconfig).value;
            setDefaultStartTime(starttime);
            // setStartTime(starttime);
            var duration= getConfigfromKey("duration",dataconfig).value;
            setNoOfDays(duration);
            var minplayer= getConfigfromKey("minplayer",dataconfig).value;
            setMinPlayer(minplayer);
            var hourcancellinglimit= getConfigfromKey("hourcancellinglimit",dataconfig).hoursLimit;
            setCancelTimeInHours(hourcancellinglimit);
        }
    }
    const getConfigfromKey =(keyvalue,data) =>{
        return data.find(item => item.key === keyvalue);

    }

    const handleDateSave = () => {
        setIsLoading(true);
        if (!noOfDays) {
            toast.current.show({ severity: "error", summary: "Failure", detail: "Please select No of days" });
        }
        else {
        const payload = {
            key: "duration",
            value: noOfDays,
            modifiedDate: today,
            modifiedBy: "Admin",
            configId: 1
        }
        console.log(payload);
        axios
            .post(constants.URL.SAVE_CONFIG, payload)
            .then((resp) => {
                // // console.log(resp);
                toast.current.show({ severity: "success", summary: "Success", detail: "No of Days Configuration Added Successfully" });
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
        }
    }
    
    const handleStartTimeSave = () => {
        setIsLoading(true);
        if (!startTime) {
            toast.current.show({ severity: "error", summary: "Failure", detail: "Please select Time of Booking" });
        }
        else {
            const slotDate = startTime 
            slotDate.setSeconds("00");
          const timeValue = new Date(slotDate).toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',  
            hour12: false,
          });
          // console.log(timeValue.replace(/( AM| PM)/, ''));
        const payload = {
            key: "starttime",
            value: timeValue.replace(/( AM| PM)/, ''),
            modifiedDate: today,
            modifiedBy: "Admin",
            configId: 2
        }
        console.log(payload);
        axios
            .post(constants.URL.SAVE_CONFIG, payload)
            .then((resp) => {
                console.log(resp);
                setDefaultStartTime(resp.data.value)
            toast.current.show({ severity: "success", summary: "Success", detail: "Start Time Configuration Added Successfully" });
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
        }
    }
    
    const handleMinPlayer = () => {
        setIsLoading(true);
        if (!minPlayer) {
            toast.current.show({ severity: "error", summary: "Failure", detail: "Please select Minimum no of Players" });
        }
        else {
        const payload = {
            key: "minplayer",
            value: minPlayer,
            modifiedDate: today,
            modifiedBy: "Admin",
            configId: 3
        }
        // console.log(payload);
        axios
            .post(constants.URL.SAVE_CONFIG, payload)
            .then((resp) => {
                // // console.log(resp);
            toast.current.show({ severity: "success", summary: "Success", detail: "Min no of Players configuration Added Successfully" });
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
        }
    }
    
    const handleCancellingHours = () => {
        setIsLoading(true);
        if (!cancelTimeInHours) {
            toast.current.show({ severity: "error", summary: "Failure", detail: "Please Enter Cancelling Time in hours" });
        }
        else {
        const payload = {
            key: "hourcancellinglimit",
            hoursLimit: cancelTimeInHours,
            modifiedDate: today,
            modifiedBy: "Admin",
            configId: 4
        }
        // console.log(payload);
        axios
            .post(constants.URL.SAVE_CONFIG, payload)
            .then((resp) => {
                // // console.log(resp);
            toast.current.show({ severity: "success", summary: "Success", detail: "Cancelling hour configuration Added Successfully" });
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
        }
    }


    return (
        <div className='os-admin-wrapper'>
            <Toast ref={toast} />
            <div className='flex justify-content-between align-items-center ds-admin-topbar'>
                <Link to='/dashboard' className='no-underline w-6'>
                    <img src={back} />
                </Link>
                <SidebarMenu />
            </div>
            <div className='os-inner'>
                <h2 className='os-heading'>Configuration</h2>
                <div className="card flex align-items-center flex justify-content-between oss-inner field mb-0 pb-0">
                    <div className='flex align-items-center field mb-0 pb-0'>
                        <span className='oss-slots'>No of days you want to show the slot for user</span>
                        <Dropdown value={noOfDays} onChange={(e) => setNoOfDays(e.value)} options={slot_combo}
                            placeholder="Days" className="w-6rem ml-2" style={{ background: "#FAFAFE", border: "1px solid #F6F5F5" }} /></div>    
                    {userDetail?.roles[0]?.roleId === 1 ?
                    <div className="mt-2 flex justify-content-end">
                                <div className='oss-add-button inline px-5 ml-3' onClick={handleDateSave}>Save</div>
                            </div>
                            : null}
                </div>
                <div className='oss-line my-5'></div>


                <div className="card flex justify-content-between align-items-center mt-2">
                    <span className='os-time-inner oss-slots flex align-items-center'>Time of Booking
                        <Calendar id="calendar-timeonly" value={startTime} onChange={(e) => setStartTime(e.value)} timeOnly hourFormat="24" className="os-time w-5rem ml-2" />
                        <img src={timer} className='os-timer-icon ml-1' style={{ background: "#FAFAFE" }} />
                       
                    </span>
                    {userDetail?.roles[0]?.roleId === 1 ?
                    <div className="mt-2 flex justify-content-end">
                        <div className='oss-add-button inline px-5' onClick={handleStartTimeSave}>Save</div>
                    </div> : null }
                </div>
                { defaultStartTime ? 
                <div className='mt-2 oss-slots'>The last Selected Time of Booking: {defaultStartTime}</div>
                : null}
                <div className='oss-line my-5'></div>


                <div className="card flex align-items-center flex justify-content-between oss-inner field mb-0 pb-0">
                    <div className='flex align-items-center field mb-0 pb-0'>
                        <span className='oss-slots'>Minimum no of Players</span>
                        <Dropdown value={minPlayer} onChange={(e) => setMinPlayer(e.value)} options={player_combo}
                            placeholder="Players" className="w-6rem ml-2" style={{ background: "#FAFAFE", border: "1px solid #F6F5F5" }} /></div>
                    {userDetail?.roles[0]?.roleId === 1 ?
                            <div className="mt-2 flex justify-content-end">
                                <div className='oss-add-button inline px-5' onClick={handleMinPlayer}>Save</div>
                            </div>
                            : null}
                </div>
                <div className='oss-line my-5'></div>
                
                <div className="card flex align-items-center flex justify-content-between oss-inner field mb-0 pb-0">
                    <div className='flex align-items-center field mb-0 pb-0'>
                        <span className='oss-slots'>No of hours before user wants to cancel the slot</span>
                        <InputText keyfilter="int" value={cancelTimeInHours} onChange={(e) => setCancelTimeInHours(e.target.value)}
                            placeholder="in hours" className="w-6rem ml-2" style={{ background: "#FAFAFE", border: "1px solid #F6F5F5" }} /></div>    
                    {userDetail?.roles[0]?.roleId === 1 ?
                    <div className="mt-2 flex justify-content-end">
                                <div className='oss-add-button inline px-5 ml-3' onClick={handleCancellingHours}>Save</div>
                            </div>
                            : null}
                </div>
                <div className='oss-line my-5'></div>
            </div>
        </div>
    )
}

export default Configuration;