import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Calendar } from 'primereact/calendar';
import SidebarMenu from '../components/SidebarMenu';
import back from '../images/icon_arrow_left.svg';
import axios from 'axios';
import constants from '../constants/constants';
import 'primeicons/primeicons.css';
import { Divider } from "primereact/divider";

function OpenedSlot() {
    let today = new Date();
    const [date, setDate] = useState(today);

    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        let today = new Date(date);
        let year = today.getFullYear();
        let month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        let day = String(today.getDate()).padStart(2, '0');

        let slotDate = `${year}-${month}-${day}`;
        const payload = {
            slotDate: slotDate
        }
        axios
            .post(constants.URL.LIST_ACTIVE_BOOKING, payload)
            .then((resp) => {
                setData(resp.data);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }, [date]);

    function printDocument() {
        window.print();
    }

    return (
        <div className='os-admin-wrapper'>
            <div className='flex justify-content-between align-items-center ds-admin-topbar'>
                <Link to='/dashboard' className='no-underline w-6'>
                    <img src={back} />
                </Link>
                <SidebarMenu />
            </div>
            <div className='os-inner'>
                <h2 className='os-heading'>Print Booking Slots</h2>
                <div className="card flex justify-content-end os-calender mt-3">
                    <Calendar value={date} onChange={(e) => setDate(e.value)} showIcon className='w-8rem' dateFormat='dd-mm-yy' />
                    <div className='oss-add-button inline px-5 ml-3' onClick={printDocument}>Print</div>
                </div>
                {(data?.filter(flag => flag.clubSlot !== null && flag.bookingStatus !== "Cancelled By User")?.length === 0) || (data?.length === 0) ?
                    <div className='text-center text-yellow-500'>
                        <Divider />
                        <span>No data</span>
                    </div>
                    :
                    <div className='card flex justify-content-between align-items-center os-card-title'>
                        <div className='w-4'>Time Slot</div>
                        <div className='w-4'>Starting Tee</div>
                        <div className='w-4'>Count</div>
                        <div className='w-7'>Players</div>
                    </div>
                }
                {data?.filter(flag => flag.clubSlot !== null && flag.bookingStatus !== "Cancelled By User")?.map((item, index) => {
                    const playersString = item?.player;
                    const playersArray = playersString.split(",");
                    const playerCount = playersArray.length;
                    console.log(playersString, playersArray);

                    var start = item?.clubSlot?.slotStartTimeStamp;
                    var end = item?.clubSlot?.slotEndTimeStamp;
                    const startTime = new Date(start);
                    const endTime = new Date(end);

                    const startHour = startTime.getHours();
                    const startMinute = startTime.getMinutes();
                    const endHour = endTime.getHours();
                    const endMinute = endTime.getMinutes();

                    // Format the start and end times
                    const startPeriod = startHour >= 12 ? "pm" : "am";
                    const endPeriod = endHour >= 12 ? "pm" : "am";

                    const startHour12 = startHour % 12 || 12;
                    const endHour12 = endHour % 12 || 12;

                    const startTimeFormatted = `${startHour12}:${startMinute < 10 ? "0" : ""}${startMinute}${startPeriod}`;
                    const endTimeFormatted = `${endHour12}:${endMinute < 10 ? "0" : ""}${endMinute}${endPeriod}`;

                    // Combine the start and end times
                    const timeRangeFormatted = `${startTimeFormatted}`;
                    // // console.log(timeRangeFormatted);
                    return (
                        <div key={index} className='card flex justify-content-between align-items-center os-card-inner'>
                            <div className='w-4'>{item?.clubSlot === null ? "" : timeRangeFormatted}</div>
                            <div className='w-4'>{item?.clubSlot?.teeTime}</div>
                            <div className='w-4'>{playerCount}</div>
                            <div className='w-7'>
                                {
                                    playersArray?.filter(item => item != 'undefined')?.map((item1, index) => {
                                        return <div><strong>{index + 1}.  </strong>{item1}</div>
                                    })
                                }
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default OpenedSlot;