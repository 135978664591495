import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useNavigate } from 'react-router-dom';
import golf from "../images/ground.png"
// import golf from "../images/golf-1.svg"
import logo from "../images/logo.svg"
import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { useStoreActions, useStoreState } from "easy-peasy";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";
import axios from "axios";
import constants from "../constants/constants";


const ForgotPassword = () => {
    const toast = useRef(null);
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);
    const [userEmail, setUserEmail] = useState();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const togglePasswordVisibility1 = () => {
        setShowPassword1(!showPassword1);
    };

    const {
        register, handleSubmit,
        // reset,
        formState: { errors },
        watch,
    } = useForm();
    const password = watch("password");

    const onSubmit = (data) => {
        const payload = {
            userEmail: data.userEmail,
        }
        setIsLoading(true);
        axios.post(constants.URL.EMAIL_VERIFICATION, payload)
            .then((resp) => {
                console.log(resp.data)
                if (resp.data.status == "Failure") {
                    toast.current.show({ severity: "error", summary: "Failure", detail: resp.data.statusMessage });
                } else {
                    setUserEmail(resp.data);
                toast.current.show({ severity: "success", summary: "Success", detail: "Email Verified Successfully & OTP sent to your Email" });
                }
                
            }).catch((e) => {
                toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.error });
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const resendOTP = () => {
        const payload = {
            userEmail: userEmail?.response?.userEmail,
        }
        setIsLoading(true);
        axios.post(constants.URL.EMAIL_VERIFICATION, payload)
            .then((resp) => {
                setUserEmail(resp.data?.response);
                toast.current.show({ severity: "success", summary: "Success", detail: "Email Verified Successfully & OTP sent to your Email" });
            }).catch((e) => {
                toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.error });
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const onSubmitOTP = (data) => {
        const payload = {
            otpNumber: data?.otp,
            userPassword: data.password,
            userEmail: userEmail?.response?.userEmail,
        }
        // console.log(payload, userEmail);

        setIsLoading(true);
        axios.post(constants.URL.RESET_PASSWORD, payload)
            .then((resp) => {
                // console.log(resp);
                navigate("/")
                toast.current.show({ severity: "success", summary: "Success", detail: "Password changed successfully" });
            }).catch((e) => {
                toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data});
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })
    }


    return (
        <div className="login-wrapper">
            <Toast ref={toast} />
            <div className="ls-page">
                <img src={golf} className="" alt="golf-ground" />
                <img src={logo} className="ls-logo" alt="golf-ground" />
                <div className="form-wrapper">
                    {!userEmail ?
                        <form onSubmit={handleSubmit(onSubmit)} className="error_msg">
                            <h4 className="ls-heading mb-3">Forgot Password</h4>
                            <p className="font-medium mb-3" style={{ color: "#fff" }}>Please enter your email address to send OTP</p>
                            <div>
                                <div className="field">
                                    <label htmlFor="email" className="block text-900 font-medium mb-2">Email</label>
                                    <InputText id="email" type="text" className="w-full"
                                        defaultValue={""}
                                        {...register("userEmail", {
                                            required: "Email Id is required.",
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: "Invalid Email.",
                                            }
                                        })}
                                    />
                            {errors?.userEmail && <p className="p-error">{errors.userEmail.message}</p>}
                                </div>
                                <div className="mt-3 flex justify-content-center">
                                    <div className="ls-btn-wrapper">
                                        <Button label={!isLoading ? "Submit" : ""} loading={isLoading} className="ls-login-btn" />
                                    </div>
                                </div>
                            </div>
                        </form>
                        :
                        <div>
                            <form onSubmit={handleSubmit(onSubmitOTP)} className="error_msg">
                                <h4 className="ls-heading mb-3">Reset Password</h4>
                                <div>
                                    <div className="field">
                                        <label htmlFor="otp" className="block text-900 font-medium mb-2">OTP</label>
                                        <InputText id="otp" type="text" className="w-full"
                                            defaultValue={""}
                                            {...register("otp", {
                                                required: true,
                                            })}
                                        />
                                        {errors?.otp?.type === "required" && <p className="p-error">This field is required</p>}
                                    </div>
                                    <div className="field">
                                        <label htmlFor="password" className="block text-900 font-medium mb-2">New Password</label>
                                        <div className="relative">
                                            <InputText id="password" className="w-full" type={showPassword ? "text" : "password"}
                                                defaultValue={""}
                                                {...register("password", {
                                                    required: "Password is required.",
                                                    minLength: {
                                                        value: 8,
                                                        message: "Password must be at least 8 characters long.",
                                                    },
                                                    pattern: {
                                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\*.,])[A-Za-z\d!@#\$%\^&\*.,]{8,}$/,
                                                        message: "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.",
                                                    }
                                                })}
                                            />
                                            <span className="absolute eye-icon-position cursor-pointer" onClick={togglePasswordVisibility}>
                                                {showPassword ? (
                                                    <i className="pi pi-eye-slash" style={{ color: '#708090', fontSize: "16px" }}></i>
                                                ) : (
                                                    <i className="pi pi-eye" style={{ color: '#708090', fontSize: "16px" }}></i>
                                                )}
                                            </span>
                                        </div>
                                        {errors?.password && <p className="p-error">{errors.password.message}</p>}
                                    </div>

                                    <div className="field mb-2 mt-1">
                                        <label htmlFor="confirmPassword" className="block text-900 font-medium mb-2">Confirm Password</label>
                                        <div className="relative">
                                            <InputText id="confirmPassword" type={showPassword1 ? "text" : "password"} className="w-full"
                                                defaultValue={""}
                                                {...register("confirmPassword", {
                                                    required: "Confirm Password is required.",
                                                    // minLength: {
                                                    //     value: 8,
                                                    //     message: "Password must be at least 8 characters long.",
                                                    // },
                                                    // pattern: {
                                                    //     value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\*.,])[A-Za-z\d!@#\$%\^&\*.,]{8,}$/,
                                                    //     message: "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.",
                                                    // },
                                                    validate: (value) => value === password, // Validate if the value matches the password field
                                                })}
                                            />
                                            <span className="absolute eye-icon-position cursor-pointer" onClick={togglePasswordVisibility1}>
                                                {showPassword1 ? (
                                                    <i className="pi pi-eye-slash" style={{ color: '#708090', fontSize: "16px" }}></i>
                                                ) : (
                                                    <i className="pi pi-eye" style={{ color: '#708090', fontSize: "16px" }}></i>
                                                )}
                                            </span>
                                        </div>

                                        {errors.confirmPassword?.type === "validate" && (
                                            <p className="p-error">Passwords do not match</p>
                                        )}
                            {errors?.confirmPassword && <p className="p-error">{errors.confirmPassword.message}</p>}
                                    </div>
                                    <div className="mt-3 flex justify-content-center">
                                        <div className="ls-btn-wrapper">
                                            <Button label="Update" className="ls-login-btn" />
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className="flex align-items-center justify-content-between">
                                <div className="ls-forget-text text-left mb-3 cursor-pointer" onClick={resendOTP}>Resend OTP</div>
                                <div className="ls-forget-text text-right mb-3 cursor-pointer" onClick={() => { navigate("/") }}>Back to Login</div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
