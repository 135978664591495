import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useNavigate } from 'react-router-dom';
import golf from "../images/ground.png"
import logo from "../images/logo.svg"
import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { useStoreActions } from "easy-peasy";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";                                       
import axios from "axios";
import constants from "../constants/constants";     

const Login = () => {
    const toast = useRef(null);
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const { register, handleSubmit, formState: { errors } } = useForm();
    const setUserDetail = useStoreActions((actions) => actions.tabModel.setUserDetail);
    const setMembersData = useStoreActions((actions) => actions.tabModel.setMembersData);

    useEffect(() => {
      setUserDetail(null);
      setMembersData(null);
    }, []);

    const onSubmit = (data) => {
        const payload = {
            userMobile: data.userEmail,
            password: data.password
        }

        setIsLoading(true);
        axios.post(constants.URL.SIGNIN,payload)
        .then((resp)=>{
            // console.log(resp);
            setUserDetail(resp.data)
            if(resp?.data?.roles[0]?.roleId == 2){
                navigate("/user-home")
            }else{
                navigate("/dashboard")
            }
        }).catch((e)=>{
            toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.error });
            console.error(e);
        }).finally(()=>{
            setIsLoading(false);
        })
    }
    
    return (
        <div className="login-wrapper">
        <Toast ref={toast} />
            <div className="ls-page">
                <img src={golf} className="" alt="golf-ground" />
                <img src={logo} className="ls-logo" alt="golf-ground" />
                <div className="form-wrapper">
                    <form onSubmit={handleSubmit(onSubmit)} className="error_msg">
                        <h4 className="ls-heading mb-3">Welcome</h4>
                        <div>
                            <div className="field">
                                <label htmlFor="email" className="block text-900 font-medium mb-2">User Name</label>
                                <InputText id="email" type="text" className="w-full"
                                    defaultValue={""}
                                    {...register("userEmail", {
                                        required: true,
                                    })}
                                />
                                {errors?.userEmail?.type === "required" && <p className="p-error">This field is required</p>}
                            </div>

                            <div className="field mb-2 mt-1">
                                <label htmlFor="password" className="block text-900 font-medium mb-2">Password</label>
                                <div className="relative">
                                    <InputText id="password" type={showPassword ? "text" : "password"} className="w-full"
                                        defaultValue={""}
                                        {...register("password", {
                                            required: true
                                        })}
                                    />
                                    <span className="absolute eye-icon-position cursor-pointer" onClick={togglePasswordVisibility}>
                                        {showPassword ? (
                                            <i className="pi pi-eye-slash" style={{ color: '#708090', fontSize: "16px" }}></i>
                                        ) : (
                                            <i className="pi pi-eye" style={{ color: '#708090', fontSize: "16px" }}></i>
                                        )}
                                    </span>
                                </div>

                                {errors?.password?.type === "required" && <p className="p-error">This field is required</p>}
                            </div>
                            <div onClick={()=> navigate("/forgot-password")} className="ls-forget-text text-right mb-3 cursor-pointer">Forgot Password?</div>
                            <div className="mt-3 flex justify-content-center">
                                <div className="ls-btn-wrapper">
                                    <Button label="LOGIN" className="ls-login-btn" />
                                </div>
                            </div>
                            <div className='absolute bottom-0 left-0 w-full text-center mt-2 ls-forget-text'>Version 3.3.1</div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
