import "react-app-polyfill/ie11";
import React from "react";
import App from "./App";
import { BrowserRouter, Navigate, Redirect, Route, Routes } from "react-router-dom";
import { useStoreState, useStoreActions } from "easy-peasy";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import OpenedSlot from "./pages/OpenedSlot";
import OpenSlot from "./pages/OpenSlot";
import BookingHistory from "./pages/BookingHistory";
import Members from "./pages/Members";
import AddMembers from "./pages/AddMembers";
import UserHome from "./pages/UserHome";
import BookSlot from "./pages/BookSlot";
import UserBooked from "./pages/UserBooked";
import Configuration from "./pages/Configuration";
import AdminBookingPage from "./pages/AdminBookingPage";
import BookingEditPage from "./pages/BookingEditPage";
import ChangePassword from "./pages/ChangePassword";
import PrintingPage from "./pages/PrintingPage";
import ProfileUpdate from "./pages/ProfileUpdate";
import ForgotPassword from "./pages/ForgotPassword";

function Root() {
  const userDetail = useStoreState((state) => state.tabModel.userDetail);
  // const role = useStoreState((state) => state.tabModel.role);
  // // console.log(userDetail?.roles[0]?.roleId);

  // Define a custom route component that checks the user role
  function CustomRoute({ element, allowedRoles }) {
      const user_role = userDetail?.roles[0]?.roleId

    if (!user_role || !allowedRoles.includes(user_role)) {
      return <Navigate to="/" replace />
    }
    return <>{element}</>;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/change-password" element={<CustomRoute allowedRoles={[1,2,3]} element={<ChangePassword />}/>} />
        <Route path="/forgot-password" element={<ForgotPassword />}/>
        <Route path="/dashboard" element={<CustomRoute allowedRoles={[1,3]} element={<Dashboard />} />} />
        <Route path="/opened-slot" element={<CustomRoute allowedRoles={[1,3]} element={<OpenedSlot />} />} />
        <Route path="/open-slot" element={<CustomRoute allowedRoles={[1,3]} element={<OpenSlot />}/>} />
        <Route path="/booking-history" element={<CustomRoute allowedRoles={[2]} element={<BookingHistory />} />} />
        <Route path="/members" element={<CustomRoute allowedRoles={[1,3]} element={<Members />}/>} />
        <Route path="/add-member" element={<CustomRoute allowedRoles={[1,3]} element={<AddMembers />}/>} />
        <Route path="/user-home" element={<CustomRoute allowedRoles={[2]} element={<UserHome />} />} />
        <Route path="/book-slot" element={<CustomRoute allowedRoles={[2]} element={<BookSlot />} />} />
        <Route path="/user-booked" element={<CustomRoute allowedRoles={[2]} element={<UserBooked />} />} />
        <Route path="/configuration" element={<CustomRoute allowedRoles={[1,3]} element={<Configuration />}/>} />
        <Route path="/admin-booking-page" element={<CustomRoute allowedRoles={[1,3]} element={<AdminBookingPage />}/>} />
        <Route path="/booking-edit-page" element={<CustomRoute allowedRoles={[1,3]} element={<BookingEditPage />}/>} />
        <Route path="/printing-page" element={<CustomRoute allowedRoles={[1,3]} element={<PrintingPage />}/>} />
        <Route path="/profile-update" element={<CustomRoute allowedRoles={[2]} element={<ProfileUpdate />}/>} />
      </Routes>
    </BrowserRouter>

  );
}

export default Root;
