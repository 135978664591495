import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import SidebarMenu from "../components/SidebarMenu";
import back from '../images/icon_arrow_left.svg';
import axios from "axios";
import constants from "../constants/constants";
import { useLocation } from "react-router-dom";

const AddMembers = () => {
    const location = useLocation();
    const { state } = location;
    const toast = useRef(null);
    const navigate = useNavigate();
    let today = new Date();
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [amob, setAmob] = useState(null);
    console.log(state);
    
    useEffect(() => {
        if (state) {
            const payload={
                userId: state?.userId,
            }
            axios
                .post(constants.URL.GET_USER_BY_ID,payload )
                .then((resp) => {
                    const data = resp.data;
                    setValue("name", data.userFname);
                    setValue("lname", data.userLname);
                    setValue("email", data.userEmail);
                    setValue("mob", data.userMobile);
                    setValue("password", data.password);
                    setValue("member", data.membershipNumber);
                })
                .catch((e) => console.error(e))
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [state]);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        const payload = {
            createdBy: "Admin",
            createdDate: today,
            membershipNumber: data.member,
            userEmail: data?.email,
            userImageUrl: "UsreprofilePicUrl",
            userLname: data?.lname,
            roleId: 2,
            userFname: data?.name,
            userMobile: data?.mob,
            userPassword: data?.password,
            fullName: data?.name +" "+ data?.lname
        }
        // console.log("s", payload);
        setIsLoading(true);
        axios.post(constants.URL.REGISTER, payload)
            .then((resp) => {
                // console.log(resp);
                if (resp.data.status == "FAILURE") {
                    toast.current.show({ severity: "error", summary: "Failure", detail: resp.data.response.response });
                } else {
                    toast.current.show({ severity: "success", summary: "Success", detail: "Member Added Successfully" });
                    reset();
                }
            }).catch((e) => {
                console.error(e.data);
                toast.current.show({ severity: "error", summary: "Failure", detail: e.data.response.response });
            }).finally(() => {
                setIsLoading(false);
            })
    }
    
    const onSubmitUpdate = (data) => {
        const payload = {
            createdBy: "Admin",
            createdDate: today,
            membershipNumber: data.member,
            userEmail: data?.email,
            userImageUrl: "UsreprofilePicUrl",
            roleId: 2,
            userFname: data?.name,
            userMobile: data?.mob,
            userLname: data?.lname,
            userId: state?.userId,
            fullName: data?.name +" "+ data?.lname
        }
        // console.log("s", payload);
        setIsLoading(true);
        axios.post(constants.URL.UPDATEPROFILE, payload)
            .then((resp) => {
                if (resp.data.status == "FAILURE") {
                    toast.current.show({ severity: "error", summary: "Failure", detail: resp.data.response.response });
                } else {
                    toast.current.show({ severity: "success", summary: "Success", detail: "Member Updated Successfully" });
                    navigate("/members");
                }
            }).catch((e) => {
                console.error(e.data);
                toast.current.show({ severity: "error", summary: "Failure", detail: e.data.response.response });
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const handleResetPassword = () => {
        const payload = {
            userPassword: "test123",
            userMobile: state?.userMobile,
        }

        setIsLoading(true);
        axios.post(constants.URL.CHANGE_PASSWORD,payload)
        .then((resp)=>{
            toast.current.show({ severity: "success", summary: "Success", detail: "Password changed successfully" });
            navigate("/members");
        }).catch((e)=>{
            toast.current.show({ severity: "error", summary: "Failure", detail: e?.response?.data?.error });
            console.error(e);
        }).finally(()=>{
            setIsLoading(false);
        })
    }

    return (
        <div className='os-admin-wrapper'>
            <Toast ref={toast} />
            <div className='flex justify-content-between align-items-center ds-admin-topbar'>
                <Link to='/members' className='no-underline w-6'>
                    <img src={back} />
                </Link>
                <SidebarMenu />
            </div>
            <div className='os-inner pt-0'>
                <h2 className='os-heading mb-4'>{!state ? "Add Members" : "Edit Members"}</h2>
                <div className="ads-form-wrapper">
                    <form className="error_msg">
                        <div className="field mb-2">
                            <label htmlFor="name" className="block text-900 font-medium mb-2">First Name<span className="p-error">*</span></label>
                            <InputText id="name" type="text" className="w-full"
                                {...register("name", {
                                    required: "First Name is required."
                                })}
                            />
                            {errors?.name && <p className="p-error">{errors.name.message}</p>}
                        </div>
                        <div className="field mb-2">
                            <label htmlFor="lname" className="block text-900 font-medium mb-2">Last Name<span className="p-error">*</span></label>
                            <InputText id="lname" type="text" className="w-full"
                                {...register("lname", {
                                    required: "Last Name is required."
                                })}
                            />
                            {errors?.lname && <p className="p-error">{errors.lname.message}</p>}
                        </div>

                        <div className="field mb-2">
                            <label htmlFor="email" className="block text-900 font-medium mb-2">Email<span className="p-error">*</span></label>
                            <InputText
                                id="email"
                                type="text"
                                className="w-full"
                                {...register("email", {
                                    required: "Email Id is required.",
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Invalid email address.",
                                    }
                                })}
                            />
                            {errors?.email && <p className="p-error">{errors.email.message}</p>}
                        </div>

                        <div className="field mb-2 pt-1">
                            <label htmlFor="mob" className="block text-900 font-medium mb-2">Mobile No<span className="p-error">*</span></label>
                            <InputText
                                id="mob"
                                className="w-full"
                                {...register("mob", {
                                    required: "Mobile number is required.",
                                    pattern: {
                                        value: /^[0-9]{10}$/,
                                        message: "Invalid mobile number. It should be 10 digits.",
                                    }
                                })}
                            />
                            {errors?.mob && <p className="p-error">{errors.mob.message}</p>}
                        </div>

{!state ?
                        <div className="field mb-2 pt-1">
                            <label htmlFor="password" className="block text-900 font-medium mb-2">Password<span className="p-error">*</span></label>
                            <div className="relative">
                                <InputText id="password" type={showPassword ? "text" : "password"}
                                    className="w-full"
                                    {...register("password", {
                                        required: "Password is required.",
                                        minLength: {
                                            value: 8,
                                            message: "Password must be at least 8 characters long.",
                                        },
                                        pattern: {
                                            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\*.,])[A-Za-z\d!@#\$%\^&\*.,]{8,}$/,
                                            message: "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.",
                                        }
                                    })}
                                />
                                <span className="absolute eye-icon-position cursor-pointer" onClick={togglePasswordVisibility}>
                                    {showPassword ? (
                                        <i className="pi pi-eye-slash" style={{ color: '#708090', fontSize: "16px" }}></i>
                                    ) : (
                                        <i className="pi pi-eye" style={{ color: '#708090', fontSize: "16px" }}></i>
                                    )}
                                </span>
                            </div>
                            {errors?.password && <p className="p-error">{errors.password.message}</p>}
                        </div> : null}

                        <div className="field mb-2 pt-1">
                            <label htmlFor="member" className="block text-900 font-medium mb-2">Membership</label>
                            <InputText id="member" className="w-full"
                                {...register("member")}
                            />
                        </div>

                        {!state ?
                            <div className="flex my-3">
                                <Button onClick={handleSubmit(onSubmit)} label="Save" id="large-btn" />
                            </div> :
                            <div className="flex my-3">
                                <Button onClick={handleSubmit(onSubmitUpdate)} label="Update" id="large-btn" className="mr-1" />
                                <Button onClick={handleSubmit(handleResetPassword)} label="Reset Password" id="large-btn" className="ml-1" />
                            </div>
                        }
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddMembers;
