import { action, persist} from "easy-peasy";


const tabModel = persist({
  userDetail: null,
  bookingResponse: null,
  membersData: null,

  setUserDetail: action((state, payload) => {
    state.userDetail = payload;

    localStorage.setItem("names", JSON.stringify(payload));
    var storedUserDetail = JSON.parse(localStorage.getItem("names"));
     state.userDetail = storedUserDetail;
  }),

  setBookingResponse: action((state, payload) => {
    state.bookingResponse = payload;
  }),

  setMembersData: action((state, payload) => {
    state.membersData = payload;
  }),
  
});

export default tabModel;