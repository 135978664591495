import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import trash from '../images/icon_trash.png';
import ticket from '../images/icon _ticket_.svg';
import SidebarMenu from '../components/SidebarMenu';
import back from '../images/icon_arrow_left.svg';
import timer from '../images/icon_timer.svg';
import calenderIcon from '../images/icon_calendar.png';
import constants from "../constants/constants";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useStoreActions, useStoreState } from "easy-peasy";
import axios from "axios";
import { InputSwitch } from 'primereact/inputswitch';
import { TabView, TabPanel } from 'primereact/tabview';
import ReactLoading from 'react-loading';
import { Checkbox } from "primereact/checkbox";
import { MultiSelect } from 'primereact/multiselect';

function OpenSlot() {
    const toast = useRef(null);
    const navigate = useNavigate();
    const [mntsPerSlot, setMntsPerSlot] = useState(null);
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [teeTime, setTeeTime] = useState(null);
    const [dates, setDates] = useState(null);
    const [enddate, setEndDates] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [checkedA, setCheckedA] = useState([]);
    const [pickDays, setPickDays] = useState([1, 2, 3, 4, 5, 6, 7]);
    const [data, setData] = useState([]);
    const type = "spokes";
    const color = "#04B54B";
    const slot_combo = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20"];

    const userDetail = useStoreState((state) => state.tabModel.userDetail);

    const dayslist = [
        { days: "Mon", value: 1 },
        { days: "Tue", value: 2 },
        { days: "Wed", value: 3 },
        { days: "Thu", value: 4 },
        { days: "Fri", value: 5 },
        { days: "Sat", value: 6 },
        { days: "Sun", value: 7 },
    ]

    const onSubmit = () => {
        // console.log(dates);
        if (!dates || !startTime || !endTime || !mntsPerSlot || !teeTime || pickDays?.length == 0) {
            toast.current.show({ severity: "error", summary: "Failure", detail: "Please Fill out the Required Fields" });
        } else {
            let slotDate = new Date(dates);
            let slotEndDate = new Date(enddate);

            let formattedMonth = (slotDate.getMonth() + 1).toString().padStart(2, '0');
            let formattedDay = slotDate.getDate().toString().padStart(2, '0');
            let formattedSlotDate = slotDate.getFullYear() + "-" + formattedMonth + "-" + formattedDay;

            let slotStartTimeStamp = new Date(startTime);
            slotStartTimeStamp.setFullYear(slotDate.getFullYear());
            slotStartTimeStamp.setMonth(slotDate.getMonth());
            slotStartTimeStamp.setDate(slotDate.getDate());
            let slotStartTimeStamps = slotStartTimeStamp;

            let slotEndTimeStamp = new Date(endTime);
            slotEndTimeStamp.setFullYear(slotEndDate.getFullYear());
            slotEndTimeStamp.setMonth(slotEndDate.getMonth());
            slotEndTimeStamp.setDate(slotEndDate.getDate());
            let slotEndTimeStamps = slotEndTimeStamp;
            slotStartTimeStamps.setMilliseconds(0);
            slotEndTimeStamp.setMilliseconds(0);
            slotStartTimeStamps.setSeconds(0);
            slotEndTimeStamp.setSeconds(0);
            const timeValue = new Date(slotStartTimeStamps).toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                timeZone: 'UTC',
                second: '2-digit',
                hour12: false,
            });

            const endtimeValue = new Date(slotEndTimeStamps).toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                timeZone: 'UTC',
                second: '2-digit',
                hour12: false,
            });

            const payload = {
                createdBy: userDetail?.createdBy,
                slotDate: formattedSlotDate,
                start: timeValue.replace(/( AM| PM)/, ''),
                end: endtimeValue.replace(/( AM| PM)/, ''),
                slotStartTimeStamp: slotStartTimeStamps,
                slotEndTimeStamp: slotEndTimeStamps,
                createdDate: new Date(),
                playerCount: 3,
                slotStatus: "Created",
                clubName: "Bhubaneswar Golf Club!",
                secondaryBooking: "Y",
                slotDuration: mntsPerSlot,
                slotAvailable: "Y",
                teeTime: teeTime,
                slotDays: pickDays
            }
            // console.log(payload);

            setIsLoading(true);
            if (startTime < endTime) {
                axios.post(constants.URL.ADDSLOT, payload)
                    .then((resp) => {
                        if (resp != null && resp.data.Status == "Success") {
                            handleOpenSlotList()
                            toast.current.show({ severity: "success", summary: "Success", detail: "Slot Added Successfully" });
                            handleReset();
                        } else if (resp != null && resp.data.Status == "Failure") {
                            toast.current.show({ severity: "error", summary: "Failure", detail: resp.data.Message });
                        }
                    }).catch((e) => {
                        toast.current.show({ severity: "error", summary: "Failure", detail: e.response.data.error });
                        console.error(e);
                    }).finally(() => {
                        setIsLoading(false);
                    })
            } else {
                toast.current.show({ severity: "error", summary: "Failure", detail: "Start time should be less than end time." });
                setIsLoading(false);
            }
        }
    }

    function adjustDays(days) {
        return days.map(day => {
            if(day == 7){
                return 0
            }else return day
        });
    }

    const handleReset = () => {
        setDates(null); setEndDates(null); setStartTime(""); setEndTime(""); setTeeTime(null); setPickDays([1, 2, 3, 4, 5, 6, 7]); setMntsPerSlot(null);
    }

    const onDelete = () => {
        // console.log(dates);
        if (!dates || !enddate || !startTime || !endTime || !teeTime || pickDays?.length == 0) {
            toast.current.show({ severity: "error", summary: "Failure", detail: "Please Fill out the Required Fields" });
        } else {
            let slotDate = new Date(dates);
            let slotEndDate = new Date(enddate);

            let formattedMonth = (slotDate.getMonth() + 1).toString().padStart(2, '0');
            let formattedDay = slotDate.getDate().toString().padStart(2, '0');
            let formattedSlotDate = slotDate.getFullYear() + "-" + formattedMonth + "-" + formattedDay;

            let slotStartTimeStamp = new Date(startTime);
            slotStartTimeStamp.setFullYear(slotDate.getFullYear());
            slotStartTimeStamp.setMonth(slotDate.getMonth());
            slotStartTimeStamp.setSeconds("00");
            slotStartTimeStamp.setDate(slotDate.getDate());
            let slotStartTimeStamps = slotStartTimeStamp;

            let slotEndTimeStamp = new Date(endTime);
            slotEndTimeStamp.setFullYear(slotEndDate.getFullYear());
            slotEndTimeStamp.setMonth(slotEndDate.getMonth());
            slotEndTimeStamp.setDate(slotEndDate.getDate());
            slotEndTimeStamp.setSeconds("00");
            slotStartTimeStamps.setMilliseconds(0);
            slotEndTimeStamp.setMilliseconds(0);
            let slotEndTimeStamps = slotEndTimeStamp;
            const timeValue = new Date(slotStartTimeStamps).toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                timeZone: 'UTC',
                second: '2-digit',
                hour12: false,
            });

            const endtimeValue = new Date(slotEndTimeStamps).toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                timeZone: 'UTC',
                second: '2-digit',
                hour12: false,
            });

            const payload = {
                createdBy: userDetail?.createdBy,
                slotDate: formattedSlotDate,
                start: timeValue.replace(/( AM| PM)/, ''),
                end: endtimeValue.replace(/( AM| PM)/, ''),
                slotStartTimeStamp: slotStartTimeStamps,
                slotEndTimeStamp: slotEndTimeStamps,
                createdDate: new Date(),
                playerCount: 3,
                slotStatus: "Created",
                clubName: "Bhubaneswar Golf Club!",
                secondaryBooking: "Y",
                slotDuration: mntsPerSlot,
                slotAvailable: "Y",
                teeTime: teeTime,
                slotDays: adjustDays(pickDays)
            }

            setIsLoading(true);
            axios.post(constants.URL.DELETESLOTRANGE, payload)
                .then((resp) => {
                    if (resp != null && resp.data.Status == "Success") {
                        handleOpenSlotList();
                        handleReset();
                        toast.current.show({ severity: "success", summary: "Success", detail: "Slot Deleted Successfully" });
                    } else if (resp != null && resp.data.Status == "Failure") {
                        toast.current.show({ severity: "error", summary: "Failure", detail: resp.data.Message });
                    }
                }).catch((e) => {
                    toast.current.show({ severity: "error", summary: "Failure", detail: e.response.data.error });
                    console.error(e);
                }).finally(() => {
                    setIsLoading(false);
                })
        }
    }

    const onBlock = () => {
        // console.log(dates);
        if (!dates || !startTime || !endTime || !mntsPerSlot || !teeTime || pickDays?.length == 0) {
            toast.current.show({ severity: "error", summary: "Failure", detail: "Please Fill out the Required Fields" });
        } else {
            let slotDate = new Date(dates);
            let slotEndDate = new Date(enddate);

            let formattedMonth = (slotDate.getMonth() + 1).toString().padStart(2, '0');
            let formattedDay = slotDate.getDate().toString().padStart(2, '0');
            let formattedSlotDate = slotDate.getFullYear() + "-" + formattedMonth + "-" + formattedDay;

            let slotStartTimeStamp = new Date(startTime);
            slotStartTimeStamp.setFullYear(slotDate.getFullYear());
            slotStartTimeStamp.setMonth(slotDate.getMonth());
            slotStartTimeStamp.setDate(slotDate.getDate());
            let slotStartTimeStamps = slotStartTimeStamp;

            let slotEndTimeStamp = new Date(endTime);
            slotEndTimeStamp.setFullYear(slotEndDate.getFullYear());
            slotEndTimeStamp.setMonth(slotEndDate.getMonth());
            slotEndTimeStamp.setDate(slotEndDate.getDate());
            let slotEndTimeStamps = slotEndTimeStamp;
            slotStartTimeStamps.setMilliseconds(0);
            slotEndTimeStamp.setMilliseconds(0);
            slotStartTimeStamps.setSeconds(0);
            slotEndTimeStamp.setSeconds(0);

            const timeValue = new Date(slotStartTimeStamps).toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                timeZone: 'UTC',
                second: '2-digit',
                hour12: false,
            });

            const endtimeValue = new Date(slotEndTimeStamps).toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                timeZone: 'UTC',
                second: '2-digit',
                hour12: false,
            });
            console.log(slotStartTimeStamps, slotEndTimeStamps);

            const payload = {
                createdBy: userDetail?.createdBy,
                slotDate: formattedSlotDate,
                start: timeValue.replace(/( AM| PM)/, ''),
                end: endtimeValue.replace(/( AM| PM)/, ''),
                slotStartTimeStamp: slotStartTimeStamps,
                slotEndTimeStamp: slotEndTimeStamps,
                createdDate: new Date(),
                playerCount: 3,
                slotStatus: "Blocked for VIP",
                clubName: "Bhubaneswar Golf Club!",
                secondaryBooking: "Y",
                slotDuration: mntsPerSlot,
                slotAvailable: "Y",
                teeTime: teeTime,
                slotDays: pickDays,
            }
            console.log(payload);

            setIsLoading(true);
            if (startTime < endTime) {
                axios.post(constants.URL.BLOCK_SLOT, payload)
                    .then((resp) => {
                        if (resp != null && resp.data.Status == "Success") {
                            handleOpenSlotList();
                            toast.current.show({ severity: "success", summary: "Success", detail: "Slot Blocked Successfully" });
                            handleReset();
                        } else if (resp != null && resp.data.Status == "Failure") {
                            toast.current.show({ severity: "error", summary: "Failure", detail: resp.data.Message });
                        }
                    }).catch((e) => {
                        toast.current.show({ severity: "error", summary: "Failure", detail: e.response.data.error });
                        console.error(e);
                    }).finally(() => {
                        setIsLoading(false);
                    })
            } else {
                toast.current.show({ severity: "error", summary: "Failure", detail: "Start time should be less than end time." });
                setIsLoading(false);
            }
        }
    }

    useEffect(() => {
        handleOpenSlotList();
    }, []);

    const handleOpenSlotList = () => {
        setIsLoading(true);
        const payload = {}
        axios
            .post(constants.URL.LIST_ALL_SLOT, payload)
            .then((resp) => {
                // // console.log(resp);
                setIsLoading(false);

                setData(resp.data.slotList);
                setCheckedA(resp.data.slotAvalaibity)

                setStartTime()
                setEndTime()
                setMntsPerSlot()
                setDates()
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);

            });
    }

    const groupedData = data?.reduce((result, item) => {
        const slotDate = formatDate(item.slotStartTimeStamp); // Extract date only
        if (!result[slotDate]) {
            result[slotDate] = [];
        }
        result[slotDate].push(item);
        return result;
    }, {});

    function formatDateApi(date) {
        let [day, month, year] = date.split('-')
        const dateObj = new Date(+year, +month - 1, +day)
        var d = new Date(dateObj),
            month1 = '' + (d.getMonth() + 1),
            day1 = '' + d.getDate(),
            year1 = d.getFullYear();

        if (month1.length < 2)
            month1 = '0' + month1;
        if (day1.length < 2)
            day1 = '0' + day1;

        return [year1, month1, day1].join('-');
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('-');
    }

    const [isChecked, setIsChecked] = useState({});

    const handleCheckedChange = (e, index, slotDate) => {
        const newIsChecked = { ...isChecked };
        newIsChecked[index] = e.value;
        checkedA[slotDate] = e.value == true ? "Y" : "N"
        setIsChecked(newIsChecked);
        let slotDateApi = formatDateApi(slotDate);

        const payload = {
            slotDate: slotDateApi,
            slotAvailable: e.value == true ? "Y" : "N"
        }
        axios
            .post(constants.URL.SLOT_AVAILABILITY, payload)
            .then((resp) => {
                // // console.log(resp);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleBooking = (item) => {
        if (item?.slotStatus === "Blocked for VIP") {
            toast.current.show({ severity: "error", summary: "Failure", detail: "The Slot is Blocked, Choose Another One" });
        } else if (item?.slotStatus != "Created") {
            toast.current.show({ severity: "error", summary: "Failure", detail: "The Slot is already Booked, Choose Another One" });
        } else {
            navigate("/admin-booking-page", { state: { item } });
        }
    }

    const handleDelete = (item) => {
        const payload = {
            slotId: item.slotId
        }
        axios
            .post(constants.URL.DELETE_SLOT, payload)
            .then((resp) => {
                handleOpenSlotList();
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });

    }

    const reject = () => {
        toast.current.show({
            severity: "warn",
            summary: "Rejected",
            detail: "You have rejected",
            life: 3000
        });
    };

    const confirm1 = (e, item) => {
        confirmDialog({
            target: e.currentTarget,
            message: "Are you sure you want to delete the Slot?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => handleDelete(item),
            reject
        });
    };

    const confirm2 = () => {
        confirmDialog({
            message: "Are you sure you want to delete the Slot?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => onDelete(),
            reject
        });
    };

    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            setPickDays([1, 2, 3, 4, 5, 6, 7]);
        } else {
            setPickDays([]);
        }
    };

    const confirmBlock = (e, item) => {
        confirmDialog({
            target: e.currentTarget,
            message: "Are you sure you want to Block the Slot?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => handleBlock(item),
        });
    };
    
    const handleBlock = (item) => {
        const payload = {
            slotId: item.slotId,
            slotStatus: "Blocked for VIP",
        }
        setIsLoading(true);
        axios
            .post(constants.URL.BLOCK_INDIVIDUAL_SLOT, payload)
            .then((resp) => {
                handleOpenSlotList();
                toast.current.show({ severity: "success", summary: "Success", detail: "Slot Blocked Successfully" });
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });

    }

    const confirmUnBlock = (e, item) => {
        confirmDialog({
            target: e.currentTarget,
            message: "Are you sure you want to UnBlock the Slot?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => handleUnBlock(item),
        });
    };
    
    const handleUnBlock = (item) => {
        const payload = {
            slotId: item.slotId,
            slotStatus: "Created",
        }
        setIsLoading(true);
        axios
            .post(constants.URL.UN_BLOCK_INDIVIDUAL_SLOT, payload)
            .then((resp) => {
                handleOpenSlotList();
                toast.current.show({ severity: "success", summary: "Success", detail: "Slot UnBlocked Successfully" });
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });

    }

    return (
        <div className='os-admin-wrapper'>
            <Toast ref={toast} />
            <div className='flex justify-content-between align-items-center ds-admin-topbar'>
                <Link to='/dashboard' className='no-underline w-6'>
                    <img src={back} />
                </Link>
                <SidebarMenu />
                <ConfirmDialog />
            </div>
            <div className='os-inner'>
            {userDetail?.roles[0]?.roleId === 1 ?
            <>
                <h2 className='os-heading'>Open Slots</h2>
                <div className="card flex align-items-center flex justify-content-between oss-inner field mb-0 pb-0">
                    <div className='flex align-items-center field mb-0 pb-0'>
                        <span className='oss-slots'>Start Date</span>
                        <Calendar value={dates} onChange={(e) => { setDates(e.value); }} showIcon className="ad-m-s w-7rem ml-2" dateFormat='dd-mm-yy' />
                    </div>
                    <div className='flex align-items-center field mb-0 pb-0'>
                        <span className='oss-slots'>End Date</span>
                        <Calendar value={enddate} onChange={(e) => { setEndDates(e.value); }} showIcon className="ad-m-s w-7rem ml-2" dateFormat='dd-mm-yy' />
                    </div>


                </div>
                <div className="card flex align-items-center flex justify-content-between oss-inner field mb-0 pb-0">
                    <div className='flex align-items-center mb-0 pb-0'>
                        <Checkbox checked={pickDays?.length > 6} onChange={handleCheckboxChange}></Checkbox>
                        <label htmlFor="pickDays" className="ml-2 oss-slots mb-0">All Days</label>
                    </div>
                    <div className='flex align-items-center field mb-0 pb-0'>
                        <span className='oss-slots'>Pick Days</span>
                        <MultiSelect value={pickDays} onChange={(e) => setPickDays(e.value)} options={dayslist} optionLabel='days'
                            className="w-6rem ml-2" style={{ background: "#FAFAFE", border: "1px solid #F6F5F5", borderRadius: "10px" }} />
                    </div>
                </div>
                <div className="card flex justify-content-between align-items-center oss-inner">
                    <span className='os-time-inner oss-slots flex align-items-center'>Start Time
                        <Calendar id="calendar-timeonly" value={startTime} onChange={(e) => setStartTime(e.value)} timeOnly hourFormat="12" className="os-time w-5rem ml-2" />
                        <img src={timer} className='os-timer-icon ml-1' style={{ background: "#FAFAFE" }} />
                    </span>
                    <span className='os-time-inner oss-slots flex align-items-center ml-1'>End Time
                        <Calendar id="calendar-timeonly" value={endTime} onChange={(e) => setEndTime(e.value)} timeOnly hourFormat="12" className="os-time w-5rem ml-2" />
                        <img src={timer} className='os-timer-icon' style={{ background: "#FAFAFE" }} />
                    </span>
                </div>
                <div className="card flex justify-content-between align-items-center oss-inner">
                    <span className='oss-slots'>Starting Tee
                        <Dropdown value={teeTime} onChange={(e) => setTeeTime(e.value)} options={["T1", "T3"]}
                            className="w-6rem ml-2" style={{ background: "#FAFAFE", border: "1px solid #F6F5F5" }} /></span>
                    <div className='flex align-items-center field mb-0 pb-0'>
                        <span className='oss-slots'>Minutes<span>/</span>Slot</span>
                        <Dropdown value={mntsPerSlot} onChange={(e) => setMntsPerSlot(e.value)} options={slot_combo}
                            className="w-6rem ml-2" style={{ background: "#FAFAFE", border: "1px solid #F6F5F5" }} />
                    </div>
                </div>

                <div className='flex justify-content-end align-items-center'>
                    <div className='oss-add-button px-5 mb-3' onClick={onSubmit}>Add</div>
                    <div className='oss-add-button px-5 mb-3 mx-2' onClick={(e) => confirm2()}>Delete</div>
                    <div className='oss-add-button px-5 mb-3' onClick={onBlock}>Block</div>
                </div>

                <div className='oss-line'></div>
                </>
                : null}
                
                <h3 className='oss-added-text' style={{ margin: "14px 0" }}>Opened Slot</h3>
                {isLoading && <ReactLoading type={type} color={color} height={'20%'} width={'20%'} />}
                {groupedData &&
                    Object.keys(groupedData)
                        .map((slotDate, index) => {
                            return (
                                <div className='mb-3' key={index}>
                                    <div className="card flex justify-content-between align-items-center mb-3">
                                        <div className='oss-slot-date-calender flex'>
                                            {slotDate} <img src={calenderIcon} className='oss-icon-cal' />
                                        </div>
                                        {userDetail?.roles[0]?.roleId === 1 ?
                                        <InputSwitch checked={checkedA[slotDate] == "Y" || false} onChange={(e) => handleCheckedChange(e, index, slotDate)} />
                                        : null}
                                    </div>
                                    <TabView>
                                        <TabPanel header="T1">
                                            <div className='grid'>
                                                {groupedData[slotDate]
                                                    .filter(flag => flag.teeTime == "T1")
                                                    .sort((a, b) => {
                                                        const startTimeA = new Date(a.slotStartTimeStamp);
                                                        const startTimeB = new Date(b.slotStartTimeStamp);
                                                        return startTimeA - startTimeB;
                                                    })
                                                    .map((item) => {
                                                        // // console.log(item);
                                                        var start = item.slotStartTimeStamp;
                                                        var end = item.slotEndTimeStamp;
                                                        const startTime = new Date(start);
                                                        const endTime = new Date(end);

                                                        const startHour = startTime.getHours();
                                                        const startMinute = startTime.getMinutes();
                                                        const endHour = endTime.getHours();
                                                        const endMinute = endTime.getMinutes();

                                                        // Format the start and end times
                                                        const startPeriod = startHour >= 12 ? "pm" : "am";
                                                        const endPeriod = endHour >= 12 ? "pm" : "am";

                                                        const startHour12 = startHour % 12 || 12;
                                                        const endHour12 = endHour % 12 || 12;

                                                        const startTimeFormatted = `${startHour12}:${startMinute < 10 ? "0" : ""}${startMinute}${startPeriod}`;
                                                        const endTimeFormatted = `${endHour12}:${endMinute < 10 ? "0" : ""}${endMinute}${endPeriod}`;

                                                        // Combine the start and end times
                                                        const timeRangeFormatted = `${startTimeFormatted}`;
                                                        return (
                                                            <div className='w-6'>
                                                                <div className={`${item.slotStatus === "Created"
                                                                    ? "oss-green-card-inner"
                                                                    : "oss-red-card-inner"
                                                                    } card flex justify-content-between m-1`}>
                                                                    <div className=''>{timeRangeFormatted}</div>
                                                                    {userDetail?.roles[0]?.roleId === 1 ?
                                                                    <div className='flex align-items-center'>
                                                                        <div className='flex'>
                                                                            {item.slotStatus !== "Blocked for VIP" ?
                                                                                <i className='pi pi-star cursor-pointer' onClick={(e) => item.slotStatus === "Created" ? confirmBlock(e, item) : null} width="12px" height="13.3px" />
                                                                                :
                                                                                <i className='pi pi-star-fill cursor-pointer' onClick={(e) => confirmUnBlock(e, item)} width="12px" height="13.3px" />
                                                                            }
                                                                        </div>
                                                                        <div className='mx-2 flex'>
                                                                            <img src={ticket} onClick={() => handleBooking(item)} alt='img' width="17px" height="13.3px" className='cursor-pointer' />
                                                                        </div>
                                                                        <div className='flex'>
                                                                            <img src={trash} onClick={(e) => confirm1(e, item)} alt='img' width="12px" height="13.3px" className='cursor-pointer' />
                                                                        </div>
                                                                    </div>
                                                                    : null}
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                            </div>
                                        </TabPanel>
                                        <TabPanel header="T3">
                                            <div className='grid'>
                                                {groupedData[slotDate]
                                                    .filter(flag => flag.teeTime !== "T1")
                                                    .sort((a, b) => {
                                                        const startTimeA = new Date(a.slotStartTimeStamp);
                                                        const startTimeB = new Date(b.slotStartTimeStamp);
                                                        return startTimeA - startTimeB;
                                                    })
                                                    .map((item) => {
                                                        // // console.log(item);
                                                        var start = item.slotStartTimeStamp;
                                                        var end = item.slotEndTimeStamp;
                                                        const startTime = new Date(start);
                                                        const endTime = new Date(end);

                                                        const startHour = startTime.getHours();
                                                        const startMinute = startTime.getMinutes();
                                                        const endHour = endTime.getHours();
                                                        const endMinute = endTime.getMinutes();

                                                        // Format the start and end times
                                                        const startPeriod = startHour >= 12 ? "pm" : "am";
                                                        const endPeriod = endHour >= 12 ? "pm" : "am";

                                                        const startHour12 = startHour % 12 || 12;
                                                        const endHour12 = endHour % 12 || 12;

                                                        const startTimeFormatted = `${startHour12}:${startMinute < 10 ? "0" : ""}${startMinute}${startPeriod}`;
                                                        const endTimeFormatted = `${endHour12}:${endMinute < 10 ? "0" : ""}${endMinute}${endPeriod}`;

                                                        // Combine the start and end times
                                                        const timeRangeFormatted = `${startTimeFormatted}`;
                                                        // // console.log(timeRangeFormatted);
                                                        return (
                                                            <div className='w-6'>
                                                                <div className={`${item.slotStatus === "Created"
                                                                    ? "oss-green-card-inner"
                                                                    : "oss-red-card-inner"
                                                                    } card flex justify-content-between m-1`}>
                                                                    <div className=''>{timeRangeFormatted}</div>
                                                                    {userDetail?.roles[0]?.roleId === 1 ?
                                                                    <div className='flex align-items-center'>
                                                                        <div className='flex'>
                                                                            {item.slotStatus !== "Blocked for VIP" ?
                                                                                <i className='pi pi-star cursor-pointer' onClick={(e) => item.slotStatus === "Created" ? confirmBlock(e, item) : null} width="12px" height="13.3px" />
                                                                                :
                                                                                <i className='pi pi-star-fill cursor-pointer' onClick={(e) => confirmUnBlock(e, item)} width="12px" height="13.3px" />
                                                                            }
                                                                        </div>
                                                                        <div className='mx-2 flex'>
                                                                            <img src={ticket} onClick={() => handleBooking(item)} alt='img' width="17px" height="13.3px" className='cursor-pointer' />
                                                                        </div>
                                                                        <div className='flex'>
                                                                            <img src={trash} onClick={(e) => confirm1(e, item)} alt='img' width="12px" height="13.3px" className='cursor-pointer' />
                                                                        </div>
                                                                    </div>
                                                                    : null}
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                            </div>
                                        </TabPanel>
                                    </TabView>
                                </div>
                            )
                        })}
                {data && !isLoading && data?.length === 0 ? <small>No data</small> : null}
            </div>
        </div>
    )
}

export default OpenSlot;