const API_URL = " https://api.teetimebgc.com/"
// const API_URL = " http://localhost:8084/"
const BASE_URL = API_URL + "users/";
const BASE_URL_SLOT = API_URL + "slot/";

const BASE_URL_BOOKING = API_URL + "booking/";
const BASE_URL_CONFIG = API_URL + "config/";

const constants = {
    URL: {
        SIGNIN: BASE_URL + "authenticate",
        REGISTER: BASE_URL + "register",
        EMAIL_VERIFICATION: BASE_URL + "forgetpassword",
        UPDATEPROFILE: BASE_URL + "updateprofile",
        LIST_USER: BASE_URL + "listuser",
        GET_USER_BY_ID: BASE_URL + "listuserByUserId",
        RESET_PASSWORD: BASE_URL + "updatepassword",
        CHANGE_PASSWORD: BASE_URL + "changepassword",
        DELETE_USER: BASE_URL + "delete",
        ADDSLOT: BASE_URL_SLOT + "addslot",
        DELETESLOTRANGE:BASE_URL_SLOT + "deleteslotrange",
        LIST_ALL_SLOT: BASE_URL_SLOT + "listallslot ",
        SLOT_LIST: BASE_URL_SLOT + "listbookingslot",
        BLOCK_SLOT: BASE_URL_SLOT + "blockslot",
        BLOCK_INDIVIDUAL_SLOT: BASE_URL_SLOT + "BlockIndividualSlot",
        UN_BLOCK_INDIVIDUAL_SLOT: BASE_URL_SLOT + "UnBlockIndividualSlot",
        SLOT_AVAILABILITY: BASE_URL_SLOT + "updateslotavailability",
        DELETE_SLOT: BASE_URL_SLOT + "deleteslot",
        BOOKING_SLOT: BASE_URL_BOOKING + "createbooking",
        BOOKING_EXISTS:BASE_URL + "listuserbookingbydate",
        LIST_ACTIVE_BOOKING: BASE_URL_BOOKING + "listactivebooking",
        DELETE_BOOKING: BASE_URL_BOOKING + "deletebooking",
        DELETE_USER_BOOKING: BASE_URL_BOOKING + "DeleteUserBooking",
        LIST_BOOKED_SLOT: BASE_URL_BOOKING + "listbookedslot",
        LIST_USER_BOOKED: BASE_URL_BOOKING + "listuserbooking",
        // LIST_USER_BOOKED: BASE_URL_BOOKING + "listuserbookingnew",
        LIST_BY_SLOT_DATE: BASE_URL_BOOKING + "listbookingbyslotdate",
        SAVE_CONFIG: BASE_URL_CONFIG + "saveconfig",
        FETCH_CONFIG: BASE_URL_CONFIG + "fetchconfig",


        APPROVAL_LIST: BASE_URL_BOOKING + "listapprovallist",
        BOOKING_UPDATE: BASE_URL_BOOKING + "bookingupdate",

        EDIT_BOOKING: BASE_URL_BOOKING + "listuserbybookingid",
        UPDATE_BOOKING: BASE_URL_BOOKING + "updateBooking",
    }
}
export default constants