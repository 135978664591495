import React, { useEffect, useState } from 'react';
import profile from '../images/golf-ds-1.svg';
import { Link, useNavigate } from 'react-router-dom';
import SidebarMenu from '../components/SidebarMenu';
import openedSlots from '../images/bookedSlots.png';
import openSlots from '../images/openSlots.png';
import { useStoreActions, useStoreState } from 'easy-peasy';
import axios from 'axios';
import constants from '../constants/constants';

function Dashboard() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const userDetail = useStoreState((state) => state.tabModel.userDetail);
    const setMembersData = useStoreActions((actions) => actions.tabModel.setMembersData);

    useEffect(() => {
        setIsLoading(true);
        const payload = {}
        axios
            .post(constants.URL.LIST_USER, payload)
            .then((resp) => {
                setMembersData(resp.data);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    return (
        <div className='ds-admin-wrapper' style={{background: "#FFFFFF"}}>
            <div className='flex justify-content-between align-items-center ds-admin-topbar' style={{background: "#2F96F9", height: 72}}>
                <h2 className='ds-welcome-txt'>Hi, {userDetail?.userFname}</h2>
                <SidebarMenu />
            </div>
            <div className='ds-wrapper' style={{ height: "100%" }}>
                <div className='grid m-0 justify-content-center'>
                <div className='no-underline pr-2 w-5'>
                    <img src={openedSlots} onClick={()=> navigate("/opened-slot")} style={{ width: "100%" }} />
                </div>
                <div to='/open-slot' className='no- pl-2 w-5'>
                    <img src={openSlots} onClick={()=> navigate("/open-slot")} style={{ width: "100%" }} />
                </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;