import React, { useEffect, useRef, useState } from "react";
import img1 from "../images/bgc-min.png";
import { Link, useNavigate } from "react-router-dom";
import SidebarMenu from "../components/SidebarMenu";
import { Button } from "primereact/button";
import axios from "axios";
import constants from "../constants/constants";
import calenderIcon from "../images/icon_calendar.png";
import { Toast } from "primereact/toast";
import { useStoreState } from "easy-peasy";
import AutoSlideCarousel from "../components/AutoSlideCarousel";
import { TabPanel, TabView } from "primereact/tabview";
import ReactLoading from 'react-loading';



function UserHome() {
  const navigate = useNavigate();
  const toast = useRef(null);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [buttonVisible, setButtonVisible] = useState(false);
  const userDetail = useStoreState((state) => state.tabModel.userDetail);
  const type="spokes";
  const color="#04B54B";

  useEffect(() => {
    setIsLoading(true);
    const payload = {};
    axios
      .post(constants.URL.SLOT_LIST, payload)
      .then((resp) => {
        // // console.log(resp);
        setIsLoading(false);
        setData(resp.data);
      })
      .catch((e) => console.error(e))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const groupedData = data?.reduce((result, item) => {
    const slotDate = formatDate(item.slotStartTimeStamp) // Extract date only
    if (!result[slotDate]) {
      result[slotDate] = [];
    }
    result[slotDate].push(item);
    return result;
  }, {});

  function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

        return [day, month, year].join('-');
}


  // // console.log(groupedData);

  const [selectedDiv, setSelectedDiv] = useState(null);
  const handleDivClick = (item) => {

     
    
    // console.log(item);
    if (item?.slotStatus != "Created") {
      toast.current.show({
        severity: "error",
        summary: "Failure",
        detail: "The Slot is already Booked, Choose Another One",
      });
      return;
    } else  if (item?.slotAvailable == "N" ) {
      toast.current.show({
        severity: "error",
        summary: "Failure",
        detail: "This slot is Blocked by Admin"
      });
      return;
    } else  if (item?.slotStarted == "N" ) {
      toast.current.show({
        severity: "error",
        summary: "Failure",
        detail: "This slot is not open for Booking and  "+item.bookingMessage
      });
      return;
    }
    
    
    else {
      
      const payload = {
        slotDate: item.slotDate,
        userId: userDetail.userId
    }



    axios.post(constants.URL.BOOKING_EXISTS, payload)
            .then((resp) => {
            
                if (resp.data.status == "SUCCESS") {
                  setSelectedDiv(item);
                  setButtonVisible(true)
                } else {
                 
                    toast.current.show({ severity: "error", summary: "Failure", detail: "You have slot Booked for today" });
                    return; 
                }
            }).catch((e) => {
                console.error(e);
            }).finally(() => {
                setIsLoading(false);
            })

    
    }
  };
  

  return (
    <div className="ds-admin-wrapper">
      <Toast ref={toast} />
      <div className="flex justify-content-between align-items-center ds-admin-topbar" style={{ height: 100 }}>
        <div >
          <h2 className="ds-welcome-txt mb-2">Hi, {userDetail?.userFname}</h2>
          <p className="us-sub-text">Welcome to Bhubaneswar Golf Club!</p>
        </div>
        <SidebarMenu />
      </div>
      <div className="ds-wrapper" style={{ height: "100%" }}>
        <h2 className="os-heading mb-4">What’s Happening</h2>
        <div className="mb-3">
          <AutoSlideCarousel />
        </div>

        <div className="flex justify-content-between align-items-center os-calender mb-3">
          <h2 className="os-heading">Opened Slots</h2>
        </div>
          { isLoading && 
                 <ReactLoading type={type} color={color} height={'20%'} width={'20%'} />
                }
        {groupedData &&
          Object.keys(groupedData).map((slotDate) => {
            // console.log(slotDate);
            return (
              <div className="mb-3" key={slotDate}>
                <div className="card flex justify-content-between align-items-center mb-3">
                  <div className="oss-slot-date-calender flex">
                    {slotDate}{" "}
                    <img src={calenderIcon} className="oss-icon-cal" />
                  </div>
                </div>
                                        <TabView>
                                            <TabPanel header="T1">
                <div className="grid px-2">
                  {groupedData[slotDate]
                                        .filter(flag => flag.teeTime == "T1")
                    .sort((a, b) => {
                      const startTimeA = new Date(a.slotStartTimeStamp);
                      const startTimeB = new Date(b.slotStartTimeStamp);
                      return startTimeA - startTimeB;
                    })
                    .map((item) => {
                      var start = item.slotStartTimeStamp;
                      var end = item.slotEndTimeStamp;
                      const startTime = new Date(start);
                      const endTime = new Date(end);

                      const startHour = startTime.getHours();
                      const startMinute = startTime.getMinutes();
                      const endHour = endTime.getHours();
                      const endMinute = endTime.getMinutes();

                      // Format the start and end times
                      const startPeriod = startHour >= 12 ? "pm" : "am";
                      const endPeriod = endHour >= 12 ? "pm" : "am";

                      const startHour12 = startHour % 12 || 12;
                      const endHour12 = endHour % 12 || 12;

                      const startTimeFormatted = `${startHour12}:${startMinute < 10 ? "0" : ""
                        }${startMinute}${startPeriod}`;
                      const endTimeFormatted = `${endHour12}:${endMinute < 10 ? "0" : ""
                        }${endMinute}${endPeriod}`;

                      // Combine the start and end times
                      const timeRangeFormatted = `${startTimeFormatted}`;

                      return (
                        <div key={item.slotId} style={{ padding: "2px", width: "20%"}} className="cursor-pointer">
                          <div
                            className={`${item.slotStarted==="N"?"us-grey-border":
                            item.slotStatus === "Created"
                              ? "us-green-border"
                                : "us-red-border"
                              } ${selectedDiv === item ? "highlighted" : ""}`}
                            onClick={() => handleDivClick(item)}
                          >
                            {timeRangeFormatted}
                          </div>
                        </div>
                      );
                    })}
                </div>
                                            </TabPanel>
                                            <TabPanel header="T3">
                <div className="grid px-2">
                  {groupedData[slotDate]
                                        .filter(flag => flag.teeTime !== "T1")
                    .sort((a, b) => {
                      const startTimeA = new Date(a.slotStartTimeStamp);
                      const startTimeB = new Date(b.slotStartTimeStamp);
                      return startTimeA - startTimeB;
                    })
                    .map((item) => {
                      var start = item.slotStartTimeStamp;
                      var end = item.slotEndTimeStamp;
                      const startTime = new Date(start);
                      const endTime = new Date(end);

                      const startHour = startTime.getHours();
                      const startMinute = startTime.getMinutes();
                      const endHour = endTime.getHours();
                      const endMinute = endTime.getMinutes();

                      // Format the start and end times
                      const startPeriod = startHour >= 12 ? "pm" : "am";
                      const endPeriod = endHour >= 12 ? "pm" : "am";

                      const startHour12 = startHour % 12 || 12;
                      const endHour12 = endHour % 12 || 12;

                      const startTimeFormatted = `${startHour12}:${startMinute < 10 ? "0" : ""
                        }${startMinute}${startPeriod}`;
                      const endTimeFormatted = `${endHour12}:${endMinute < 10 ? "0" : ""
                        }${endMinute}${endPeriod}`;

                      // Combine the start and end times
                      const timeRangeFormatted = `${startTimeFormatted}`;

                      return (
                        <div key={item.slotId} style={{ padding: "2px", width: "20%"}} className="cursor-pointer">
                          <div
                            className={`${item.slotStarted==="N"?"us-grey-border":
                            item.slotStatus === "Created"
                              ? "us-green-border"
                                : "us-red-border"
                              } ${selectedDiv === item ? "highlighted" : ""}`}
                            onClick={() => handleDivClick(item)}
                          >
                            {timeRangeFormatted}
                          </div>
                        </div>
                      );
                    })}
                </div>
                                            </TabPanel>
                                        </TabView>
              </div>
            );
          })}

        {/* <Link to='/book-slot' className='no-underline w-6'> */}
        { buttonVisible ? 
        <div className="mt-3 floating_btn">
          <Button
            className="mb-3"
            label="Proceed to Booking"
            id="large-btn"
            onClick={() => {
              navigate("/book-slot", { state: { selectedDiv } });
              setButtonVisible(false)
            }}
          />
        </div>
        : null }
        {/* </Link> */}
      </div>
    </div>
  );
}

export default UserHome;
