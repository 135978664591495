import React, { useEffect, useRef, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import img1 from "../images/bgc-min.png";
import img2 from "../images/bgClub4.jpeg";
import img3 from "../images/BgCLub3.jpeg";
import img4 from "../images/BgGClub2.jpeg";
import img5 from "../images/BGclub1.jpeg";

import constants from '../constants/constants';
import axios from 'axios';
import { useStoreState } from 'easy-peasy';
import { Toast } from "primereact/toast";

// ....

// className "owl-theme" is optional

function AutoSlideCarousel() {

    const userDetail = useStoreState((state) => state.tabModel.userDetail);
    const [isLoading, setIsLoading] = useState(false);
    const [approvalData, setApprovalData] = useState();
    const toast = useRef(null);

  useEffect(() => {
    setIsLoading(true);
    const payload = {
      userId: userDetail?.userId
    };
    axios
      .post(constants.URL.APPROVAL_LIST, payload)
      .then((resp) => {
        // // console.log(resp);
        setApprovalData(resp.data);
      })
      .catch((e) => console.error(e))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleApproval = (item) =>{
    // console.log(item);
    setIsLoading(true);
    const payload = {
        bookingId: item?.bookingId,
        bookingStatus: "Approved" ,     ///    Approved
        slotId: item?.slotId,
        primaryBookingId: item?.primaryBookingId,
        playerCount: item?.playerCount,
        
    };
    // console.log(payload);
    axios
      .post(constants.URL.BOOKING_UPDATE, payload)
      .then((resp) => {
        // console.log(resp);
        toast.current.show({ severity: "success", summary: "Success", detail: "Secondary Approval Approved" });
      })
      .catch((e) => console.error(e))
      .finally(() => {
        setIsLoading(false);
      });
  }
  
  const handleDecline = (item) =>{
    // console.log(item);
    setIsLoading(true);
    const payload = {
        bookingId: item?.bookingId,
        bookingStatus: "Rejected" ,     ///    Approved
        slotId: item?.slotId,
        primaryBookingId: item?.primaryBookingId,
        playerCount: item?.playerCount,
        
    };
    // console.log(payload);
    axios
      .post(constants.URL.BOOKING_UPDATE, payload)
      .then((resp) => {
        // console.log(resp);
        toast.current.show({ severity: "success", summary: "Success", detail: "Approval Rejected" });
      })
      .catch((e) => console.error(e))
      .finally(() => {
        setIsLoading(false);
      });
  }

    return (
        <div>
        <Toast ref={toast} />
            
        <OwlCarousel className='owl-theme' autoplayHoverPause={true} loop margin={10} autoplay={true} dots={false}
            responsive={
                {
                    '1': {
                        items: 1.7
                    },
                    '1025': {
                        items: 2
                    }
                }

            }>
                {approvalData?.map((item)=>{
                const slotDate = item?.clubSlot?.slotDate
                const formattedDate = new Date(slotDate).toLocaleDateString('en-US', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric'
                });


                // console.log(formattedDate); // Output: "11 June, 2023"


                    let time = item?.clubSlot?.slotStartTimeStamp
                    const date = new Date(time);
                    const hours = date.getHours();
                    const minutes = date.getMinutes();
                    let formattedHours = hours % 12;
                    formattedHours = formattedHours === 0 ? 12 : formattedHours;
                    const period = hours >= 12 ? 'PM' : 'AM';
                    const formattedTime = `${formattedHours}:${minutes.toString().padStart(2, '0')} ${period}`;

                    // console.log(formattedTime); // Output: "10:56 PM"
                    return(
                        <div class='item'>
                <div className="us-approval-bg us-c-h">
                    <h2 className="us-approval-heading text-center">Approval Request</h2>
                    <div className="flex justify-content-between">
                        <div className="flex justify-content-center flex-column align-items-center">
                            <h3 className="us-approval-title">Date</h3>
                            <p className="us-approval-value">{formattedDate}</p>
                        </div>
                        <div className="flex justify-content-center flex-column align-items-center">
                            <h3 className="us-approval-title">Time</h3>
                            <p className="us-approval-value">{formattedTime}</p>
                        </div>
                    </div>
                    <div className="us-line"></div>
                        <div className="flex justify-content-center flex-column align-items-center">
                    <h2 className="us-approval-title">Requester Name</h2>
                    <p className="us-approval-value">{item?.player}</p>
                    </div>
                    <div className="flex justify-content-center">
                        <div className="us-approval-btn us-green" onClick={()=> handleApproval(item)}>Approve</div>
                        <div className="us-approval-btn us-red ml-2" onClick={()=> handleDecline(item)}>Decline</div>
                    </div>
                </div>
            </div>
                    )
                })}
            
            <div class='item'>
                <img src={img2} className="w-full us-c-h" />
            </div>
            <div class='item'>
                <img src={img3} className="w-full us-c-h" />
            </div>
            <div class='item'>
                <img src={img4} className="w-full us-c-h" />
            </div>
            <div class='item'>
                <img src={img5} className="w-full us-c-h" />
            </div>
            <div class='item'>
                <img src={img1} className="w-full us-c-h" />
            </div>

        </OwlCarousel>
        </div>
    )
}


export default AutoSlideCarousel