import React, { useRef, useState }  from 'react';
import { useNavigate } from 'react-router-dom';
import profile from '../images/menu.svg';
import { Sidebar } from 'primereact/sidebar';
import { useStoreActions, useStoreState } from 'easy-peasy';
        
function SidebarMenu() {
    const menu = useRef(null);
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const setUserDetail = useStoreActions((actions) => actions.tabModel.setUserDetail);
    // const setIsAuthenticated = useStoreActions((actions) => actions.tabModel.setIsAuthenticated);
  const userDetail = useStoreState((state) => state.tabModel.userDetail);
  const setMembersData = useStoreActions((actions) => actions.tabModel.setMembersData);

    let items = [
        {
            label: "Logout",
            icon: 'pi pi-fw pi-power-off',
            command: () => {
                navigate("/");
                setMembersData(null);
                // setIsAuthenticated(false)
            },
        },
    ];
    const customIcons = (
        <React.Fragment>
            <h2 className="sb-welcome-text">Welcome {userDetail?.userFname}</h2>
        </React.Fragment>
    );

    return (
        <div className="db-topbar">
            <Sidebar visible={visible} onHide={() => setVisible(false)}  icons={customIcons}>
                {userDetail && (userDetail?.roles[0]?.roleId == 1 || userDetail?.roles[0]?.roleId == 3) ? 
                <a className='no-underline sb-menu' onClick={()=> navigate("/members")}>Members</a>
                : null }
                {userDetail && (userDetail?.roles[0]?.roleId == 1 || userDetail?.roles[0]?.roleId == 3) ? 
                <a className='no-underline sb-menu' onClick={()=> navigate("/configuration")}>Configuration</a>
                : null }
                {userDetail && (userDetail?.roles[0]?.roleId == 1 || userDetail?.roles[0]?.roleId == 3) ? 
                <a className='no-underline sb-menu' onClick={()=> navigate("/printing-page")}>Print Booking Slots</a>
                : null }
                {userDetail && userDetail?.roles[0]?.roleId == 2 ? 
                    <a className='no-underline sb-menu' onClick={()=> navigate("/profile-update")}>Profile Edit</a>
                : null }
                {userDetail && userDetail?.roles[0]?.roleId == 2 ? 
                <a className='no-underline sb-menu' onClick={()=> navigate("/booking-history")}>Booking History</a>
                : null }
                <a className='no-underline sb-menu' onClick={()=> {navigate("/change-password")}}>Change Password</a>
                <a className='no-underline sb-menu' onClick={()=> {navigate("/");
                //  setUserDetail([]);
                 }}>Sign Out</a>
                 {/* <div className='absolute bottom-0 left-0 w-full text-center mb-2'>Version 3.1</div> */}
            </Sidebar>
            <img src={profile} className='s-tb-img' alt='img' onClick={() => setVisible(true)} />
        </div>
    );
}

export default SidebarMenu;