import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { useStoreState } from "easy-peasy";
import SidebarMenu from "../components/SidebarMenu";
import back from '../images/icon_arrow_left.svg';
import axios from "axios";
import constants from "../constants/constants";

const ProfileUpdate = () => {
    const toast = useRef(null);
    let today = new Date();
    const userDetail = useStoreState((state) => state.tabModel.userDetail);
    // console.log(userDetail);

    const [isLoading, setIsLoading] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        const payload = {
            createdBy: userDetail?.userFname,
            createdDate: today,
            userLname: data?.lname,
            roleId: 2,
            userFname: data?.name,
            userMobile: data?.mob,
            userEmail: data?.email,
            userId: userDetail?.userId,
            fullName: data?.name +" "+ data?.lname
        }

        setIsLoading(true);
        axios.post(constants.URL.UPDATEPROFILE, payload)
            .then((resp) => {
                // console.log(resp);
                if (resp.data.status == "FAILURE") {
                    toast.current.show({ severity: "error", summary: "Failure", detail: resp.data.response.response });
                } else {
                    toast.current.show({ severity: "success", summary: "Success", detail: "Profile Updated Successfully" });
                }
            }).catch((e) => {
                console.error(e.data);
                toast.current.show({ severity: "error", summary: "Failure", detail: e.data.response.response });
            }).finally(() => {
                setIsLoading(false);
            })
    }


    return (
        <div className='os-admin-wrapper'>
            <Toast ref={toast} />
            <div className='flex justify-content-between align-items-center ds-admin-topbar'>
                <Link to='/user-home' className='no-underline w-6'>
                    <img src={back} />
                </Link>
                <SidebarMenu />
            </div>
            <div className='os-inner pt-0'>
                <h2 className='os-heading mb-4'>Profile Edit</h2>
                <div className="ads-form-wrapper">
                    <form onSubmit={handleSubmit(onSubmit)} className="error_msg">
                        <div className="field mb-2">
                            <label htmlFor="name" className="block text-900 font-medium mb-2">First Name<span className="p-error">*</span></label>
                            <InputText id="name" type="text" className="w-full" defaultValue={userDetail?.userFname}
                                {...register("name", {
                                    required: true,
                                })} />
                            {errors?.name?.type === "required" && <p className="p-error">This field is required</p>}

                        </div>

                        <div className="field mb-2">
                            <label htmlFor="lname" className="block text-900 font-medium mb-2">Last Name<span className="p-error">*</span></label>
                            <InputText id="lname" type="text" className="w-full" defaultValue={userDetail?.userLname}
                                {...register("lname", {
                                    required: true,
                                })} />
                            {errors?.lname?.type === "required" && <p className="p-error">This field is required</p>}

                        </div>

                        <div className="field mb-2 pt-1">
                            <label htmlFor="mob" className="block text-900 font-medium mb-2">Mobile No<span className="p-error">*</span></label>
                            <InputText id="mob" className="w-full" defaultValue={userDetail?.userMobile}
                                {...register("mob", {
                                    required: true,
                                })} />
                            {errors?.mob?.type === "required" && <p className="p-error">This field is required</p>}
                        </div>

                        <div className="field mb-2">
                            <label htmlFor="email" className="block text-900 font-medium mb-2">Email<span className="p-error">*</span></label>
                            <InputText
                                id="email"
                                type="text"
                                defaultValue={userDetail?.userEmail}
                                className="w-full"
                                {...register("email", {
                                    required: "Email Id is required.",
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Invalid email address.",
                                    }
                                })}
                            />
                            {errors?.email && <p className="p-error">{errors.email.message}</p>}
                        </div>

                        <div className="my-3">
                            <Button label="Update" id="large-btn" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ProfileUpdate;
